export * from '@oracle-cx-commerce/react-widgets';

export const TRXAccountTypeSelectorContainer = () =>
  import('./TRX/profile/trx-accounts-contacts-registration-container');
export const TRXAccountsContactsRegistrationContainer = () =>
  import('./TRX/profile/trx-accounts-contacts-registration-container');
export const TRXAccountsContactsRegistration = () => import('./TRX/profile/trx-accounts-contacts-registration');
export const TRXCartContainer = () => import('./TRX/cart/trx-cart-container');
export const TRXCartCouponContainer = () => import('./TRX/cart/trx-cart-coupon-container');
export const TRXCartItemDetails = () => import('./TRX/cart/trx-cart-item-details');
export const TRXCartOrderSummary = () => import('./TRX/cart/trx-cart-order-summary');
export const TRXCartPromotionCodeEntry = () => import('./TRX/cart/trx-cart-promotion-code-entry');
export const TRXCartPromotionsDisplay = () => import('./TRX/cart/trx-cart-promotions-display');
export const TRXCartResumeContainer = () => import('./TRX/cart/trx-cart-resume-container');
export const TRXCategoryContentContainer = () => import('./TRX/product-listing/trx-category-content-container');
export const TRXCategoryLongDescription = () => import('./TRX/product-listing/trx-category-long-description');
export const TRXCategorySearchTermsSummary = () => import('./TRX/category/trx-category-search-terms-summary');
export const TRXCheckoutBackToPreviousButton = () => import('./TRX/checkout/trx-checkout-back-to-previous-button');
export const TRXCheckoutButton = () => import('./TRX/checkout/trx-checkout-button');
export const TRXCheckoutContinueToPaymentButton = () =>
  import('./TRX/checkout/trx-checkout-continue-to-payment-button');
export const TRXCheckoutContinueToReviewOrderButton = () =>
  import('./TRX/checkout/trx-checkout-continue-to-review-order-button');
export const TRXCheckoutCreditCard = () => import('./TRX/checkout/trx-checkout-credit-card');
export const TRXCheckoutCustomerEmail = () => import('./TRX/checkout/trx-checkout-customer-email');
export const TRXCheckoutNavigationContainer = () => import('./TRX/checkout/trx-checkout-navigation-container');
export const TRXCheckoutOrderConfirmation = () => import('./TRX/checkout/trx-checkout-order-confirmation');
export const TRXCheckoutOrderConfirmationContainer = () =>
  import('./TRX/checkout/trx-checkout-order-confirmation-container');
export const TRXCheckoutPaymentMethodsContainer = () => import('./TRX/checkout/trx-checkout-payment-methods-container');
export const TRXCheckoutPlaceOrderButton = () => import('./TRX/checkout/trx-checkout-place-order-button');
export const TRXCheckoutProgressTracker = () => import('./TRX/checkout/trx-checkout-progress-tracker');
export const TRXCheckoutRegistration = () => import('./TRX/checkout/trx-checkout-registration');
export const TRXCheckoutReviewOrderContainer = () => import('./TRX/checkout/trx-checkout-review-order-container');
export const TRXCheckoutShippingContainer = () => import('./TRX/checkout/trx-checkout-shipping-container');
export const TRXCheckoutShippingInformation = () => import('./TRX/checkout/trx-checkout-shipping-information');
export const TRXCheckoutSingleShippingDetails = () => import('./TRX/checkout/trx-checkout-single-shipping-details');
export const TRXCheckoutLogin = () => import('./TRX/checkout/trx-checkout-login');
export const TRXCheckoutReviewOrderPaymentInformation = () =>
  import('./TRX/checkout/trx-checkout-review-order-payment-information');
export const TRXCheckoutPaymentsContainer = () => import('./TRX/checkout/trx-checkout-payments-container');
export const TRXCheckoutPayInStore = () => import('./TRX/checkout/trx-checkout-pay-in-store');
export const TRXCheckoutPix = () => import('./TRX/checkout/trx-checkout-pix');
export const TRXCheckoutGiftCard = () => import('./TRX/checkout/trx-checkout-gift-card');
export const NorteSulOrderDetailButton = () => import('./TRX/checkout/nortesul-order-detail-button');
export const TRXCommonAlignComponents = () => import('./TRX/common/trx-common-align-components');
export const TRXCommonContainer = () => import('./TRX/common/trx-common-container');
export const TRXCommonCookieControl = () => import('./TRX/common/trx-common-cookie-control');
export const TRXCommonDynamicBreadcrumbs = () => import('./TRX/common/trx-common-dynamic-breadcrumbs');
export const TRXCommonErro500 = () => import('./TRX/common/trx-common-erro-500');
export const TRXCommonError404 = () => import('./TRX/common/trx-common-error-404');
export const TRXCommonImage = () => import('./TRX/common/trx-common-image');
export const TRXCommonImageCarousel = () => import('./TRX/common/trx-common-image-carousel');
export const TRXCommonProductRecommendationsCarousel = () =>
  import('./TRX/common/trx-common-product-recommendations-carousel');
export const TRXCommonLogo = () => import('./TRX/common/trx-common-logo');
export const TRXCommonSearchBoxDesktop = () => import('./TRX/common/trx-common-search-box-desktop');
export const TRXCommonSearchBoxMobile = () => import('./TRX/common/trx-common-search-box-mobile');
export const TRXCommonShowMoreProductsButton = () => import('./TRX/common/trx-common-show-more-products-button');
export const TRXCommonNotifications = () => import('./TRX/common/trx-common-notifications');
export const TRXContinueShoppingButton = () => import('./TRX/common/trx-continue-shopping-button');
export const TRXFooter = () => import('./TRX/footer/trx-footer');
export const TRXFooterBottom = () => import('./TRX/footer/trx-footer-bottom');
export const TRXFooterContact = () => import('./TRX/footer/trx-footer-contact');
export const TRXFooterContainerLinks = () => import('./TRX/footer/trx-footer-container-links');
export const TRXFooterLinksList = () => import('./TRX/footer/trx-footer-links-list');
export const TRXFooterPayments = () => import('./TRX/footer/trx-footer-payments');
export const TRXFooterSeparator = () => import('./TRX/footer/trx-footer-separator');
export const TRXHeaderCollectionNavigation = () => import('./TRX/header/trx-header-collection-navigation');
export const TRXHeaderCollectionNavigationDesktop = () =>
  import('./TRX/header/trx-header-collection-navigation-desktop');
export const TRXHeaderAccountPicker = () => import('./TRX/header/trx-header-account-picker');
export const TRXHeaderContainerDesktop = () => import('./TRX/header/trx-header-container/desktop');
export const TRXHeaderContainerMobile = () => import('./TRX/header/trx-header-container/mobile');
export const TRXHeaderIconsContainer = () => import('./TRX/header/trx-header-icons-container');
export const TRXHeaderMenuMobile = () => import('./TRX/header/trx-header-menu-mobile');
export const TRXHeaderMiniCartDesktop = () => import('./TRX/header/trx-header-mini-cart/desktop');
export const TRXHeaderMiniCartMobile = () => import('./TRX/header/trx-header-mini-cart/mobile');
export const TRXHeaderTopBar = () => import('./TRX/header/trx-header-top-bar');
export const TRXHeaderUserProfileLinksDesktop = () => import('./TRX/header/trx-header-user-profile-links/desktop');
export const TRXHeaderUserProfileLinksMobile = () => import('./TRX/header/trx-header-user-profile-links/mobile');
export const TRXLogin = () => import('./TRX/login/trx-login');
export const TRXLoginContainer = () => import('./TRX/login/trx-login-container');
export const TRXLoginFacebook = () => import('./TRX/login/trx-login-facebook');
export const TRXLoginGoogle = () => import('./TRX/login/trx-login-google');
export const TRXCommonNavigationBreadcrumbs = () => import('./TRX/common/trx-common-navigation-breadcrumbs');
export const TRXProductAddToCartButton = () => import('./TRX/product/trx-product-add-to-cart-button');
export const TRXProductAddToPurchaselist = () => import('./TRX/product/trx-product-add-to-purchase-list');
export const TRXProductBreadcrumbs = () => import('./TRX/product/trx-product-breadcrumbs');
export const TRXProductInventoryStatus = () => import('./TRX/product/trx-product-inventory-status');
export const TRXProductShortDescription = () => import('./TRX/product/trx-product-short-description');
export const TRXProductListingCategoryImageCarousel = () =>
  import('./TRX/product-listing/trx-product-listing-category-image-carousel');
export const TRXProductListingDynamicProductList = () =>
  import('./TRX/product-listing/trx-product-listing-dynamic-product-list');
export const TRXProductListingDynamicProductListingContainer = () =>
  import('./TRX/product-listing/trx-product-listing-dynamic-product-listing-container');
export const TRXProductListingFacetedNavigation = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation');
export const TRXProductListingProductListingSummaryInformation = () =>
  import('./TRX/product-listing/trx-product-listing-product-listing-summary-information');
export const TRXProductListingProductResultColorSwatches = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-color-swatches');
export const TRXProductListingProductResultImage = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-image');
export const TRXProductListingProductResultImageQuickView = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-image-quick-view');
export const TRXProductListingProductResultName = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-name');
export const TRXProductListingSortResults = () => import('./TRX/product-listing/trx-product-listing-sort-results');
export const TRXProductName = () => import('./TRX/product/trx-product-name');
export const TRXProductPrice = () => import('./TRX/product/trx-product-price');
export const TRXProductProductDetailsContainer = () => import('./TRX/product/trx-product-product-details-container');
export const TRXProductProductImageViewer = () => import('./TRX/product/trx-product-product-image-viewer');
export const TRXProductProductLongDescription = () => import('./TRX/product/trx-product-product-long-description');
export const TRXProductProductSocialSharing = () => import('./TRX/product/trx-product-product-social-sharing');
export const TRXExtendedCartItemDetails = () => import('./TRX/cart/trx-extended-cart-item-details');
export const TRXProductDeliveryOptions = () => import('./TRX/product/trx-product-delivery-options');
export const TRXProfileAddToPurchaseList = () => import('./TRX/profile/trx-profile-add-to-purchase-list');
export const TRXProductProductConfigure = () => import('./TRX/product/trx-product-product-configure');
export const TRXProductProductVariantOptions = () => import('./TRX/product/trx-product-product-variant-options');
export const TRXProductQuantity = () => import('./TRX/product/trx-product-quantity');
export const TRXProductRecommendationsCarousel = () => import('./TRX/product/trx-product-recommendations-carousel');
export const TRXProductResultPrice = () => import('./TRX/product-listing/trx-product-result-price');
export const TRXProductShippingEstimate = () => import('./TRX/product/trx-product-shipping-estimate');
export const TRXProductListingFacetedNavigationMobile = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation/mobile');
export const TRXProductListingFacetedNavigationMobileContainer = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation-mobile-container');
export const TRXProfileAccountAddressBook = () => import('./TRX/profile/trx-profile-account-address-book');
export const TRXProfileAddressBook = () => import('./TRX/profile/trx-profile-address-book');
export const TRXProfileAddressBookSummary = () => import('./TRX/profile/trx-profile-address-book-summary');
export const TRXProfileAddressForm = () => import('./TRX/profile/trx-profile-address-form');
export const TRXProfileContainer = () => import('./TRX/profile/trx-profile-container');
export const TRXProfileContainerSection = () => import('./TRX/profile/trx-profile-container-section');
export const TRXProfileDetailsSummary = () => import('./TRX/profile/trx-profile-details-summary');
export const TRXProfileEmailMarketingPreferencesSummary = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences-summary');
export const TRXProfileNavigationBreadCrumbs = () => import('./TRX/profile/trx-profile-navigation-bread-crumbs');
export const TRXProfilePurchaseLists = () => import('./TRX/profile/trx-profile-purchase-lists');
export const TRXProfilePurchaseListDetailsContainer = () =>
  import('./TRX/profile/trx-profile-purchase-list-details-container');
export const TRXProfileRecentOrders = () => import('./TRX/profile/trx-profile-recent-orders');
export const TRXProfileResetPassword = () => import('./TRX/profile/trx-profile-reset-password');
export const TRXProfileSavedCardSummary = () => import('./TRX/profile/trx-profile-saved-card-summary');
export const TRXProfileShippingInformation = () => import('./TRX/profile/trx-profile-shipping-information');
export const TRXProfileUpdatePassword = () => import('./TRX/profile/trx-profile-update-password');
export const TRXProfileUpdateExpiredPassword = () => import('./TRX/profile/trx-profile-update-expired-password');
export const TRXProfileSavedCards = () => import('./TRX/profile/trx-profile-saved-cards');
export const TRXProfileAddCreditCard = () => import('./TRX/profile/trx-profile-add-credit-card');
export const TRXProfileDetails = () => import('./TRX/profile/trx-profile-details');
export const TRXProfileOrderHistory = () => import('./TRX/profile/trx-profile-order-history');
export const TRXProfileCreatePurchaseList = () => import('./TRX/profile/trx-profile-create-purchase-list');
export const TRXProfilePurchaseListsSummary = () => import('./TRX/profile/trx-profile-purchase-lists-summary');
export const TRXProfilePurchaseListSummaryInformation = () =>
  import('./TRX/profile/trx-profile-purchase-list-summary-information');
export const TRXProfilePurchaseListActionButtons = () =>
  import('./TRX/profile/trx-profile-purchase-list-action-buttons');
export const TRXProfilePurchaseListInformation = () => import('./TRX/profile/trx-profile-purchase-list-information');
export const TRXProfileWelcome = () => import('./TRX/profile/trx-profile-welcome');
export const TRXProfileEmailMarketingPreferences = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences');
export const TRXProfileEmailMarketingPreferencesContainer = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences-container');
export const TRXProfileAccountDetails = () => import('./TRX/profile/trx-profile-account-details');
export const TRXProfileOrderDetailsContainer = () => import('./TRX/profile/trx-profile-order-details-container');
export const TRXProfileOrderInformation = () => import('./TRX/profile/trx-profile-order-information');
export const TRXProfileReturnsSummaryContainer = () => import('./TRX/profile/trx-profile-returns-summary-container');
export const TRXProfilePaymentInformation = () => import('./TRX/profile/trx-profile-payment-information');
export const TRXProfileOrderSummary = () => import('./TRX/profile/trx-profile-order-summary');
export const TRXProfileOrderActions = () => import('./TRX/profile/trx-profile-order-actions');
export const TRXCommonRedoOrderButton = () => import('./TRX/common/trx-common-redo-order-button');
export const TRXProfileReturnRequestId = () => import('./TRX/profile/trx-profile-return-request-id');
export const TRXProfileReturnDetailsLink = () => import('./TRX/profile/trx-profile-return-details-link');
export const TRXProfileReturnRequestItems = () => import('./TRX/profile/trx-profile-return-request-items');
export const TRXProfileRegistration = () => import('./TRX/profile/trx-profile-registration');
export const TRXProfileRegistrationContainer = () => import('./TRX/profile/trx-profile-registration-container');
export const TRXProductListingProductCategoryName = () =>
  import('./TRX/product-listing/trx-product-listing-product-category-name');
export const TRXProfileAddressBookContainer = () => import('./TRX/profile/trx-profile-address-book-container');
export const TRXProfileOrderHistoryBreadcrumb = () => import('./TRX/profile/trx-profile-order-history-breadcrumb');
export const TRXProfileContactRegistrationRequests = () =>
  import('./TRX/profile/trx-profile-contact-registration-requests');
export const HerbalifeWebContent = () => import('./TRX/poc-herbalife/herbalife-web-content');
export const JostensDynamicSlider = () => import('./TRX/poc-jostens/jostens-dynamic-slider');
export const TRXCategoryImages = () => import('./TRX/category/trx-category-images');
export const TRXCommonNewsletter = () => import('./TRX/common/trx-common-newsletter');
export const TRXDynamicContent = () => import('./TRX/category/trx-dynamic-content');
export const TRXCheckoutTermInvoice = () => import('./TRX/checkout/trx-checkout-term-invoice');
export const TRXProductAddOnItems = () => import('./TRX/product/trx-product-add-on-items');
export const TRXProductQuickView = () => import('./TRX/product/trx-product-quick-view');
export const TRXProductNotifyMe = () => import('./TRX/product/trx-product-notify-me');
export const TRXCheckoutGenericPayment = () => import('./TRX/checkout/trx-checkout-generic-payment');
export const TRXProductDigitalVariants = () => import('./TRX/product/trx-product-digital-variants');
export const TRXProductDigitalShopperInputs = () => import('./TRX/product/trx-product-digital-shopper-inputs');
export const TRXCheckoutSingleShippingDetailsDigitalProducts = () =>
  import('./TRX/checkout/trx-checkout-single-shipping-details-digital-products');
export const TRXProductDigitalTermsAndConditions = () =>
  import('./TRX/product/trx-product-digital-terms-and-conditions');
export const TRXProductDigitalAddToCartButton = () => import('./TRX/product/trx-product-digital-add-to-cart-button');
export const TRXProductDigitalName = () => import('./TRX/product/trx-product-digital-name');
export const TRXProductListingQuickView = () => import('./TRX/product/trx-product-listing-quick-view');
export const TRXCartClosenessQualifier = () => import('./TRX/cart/trx-cart-closeness-qualifier');
export const TRXCartDigitalProductsEditModal = () => import('./TRX/cart/trx-cart-digital-products-edit-modal');
export const TRXCheckoutMultiShippingWithBopisDetails = () =>
  import('./TRX/checkout/trx-checkout-multi-shipping-with-bopis-details');
export const TRXCheckoutMultiShippingDetails = () => import('./TRX/checkout/trx-checkout-multi-shipping-details');
export const TRXCommonManualCarouselProduct = () => import('./TRX/common/trx-common-manual-carousel-product');
export const TRXCommonCategory = () => import('./TRX/common/trx-common-category');
export const TRXCountryStoreLanguageCurrencyDesktop = () =>
  import('./TRX/header/trx-country-store-language-currency-picker/desktop');
export const TRXCountryStoreLanguageCurrencyMobile = () =>
  import('./TRX/header/trx-country-store-language-currency-picker/mobile');
export const TRXFooterFinishButton = () => import('./TRX/footer/trx-footer-finish-button');
export const TRXProductThumbsVariantOptions = () => import('./TRX/product/trx-product-thumbs-variant-options');
export const NorteSulCollapsible = () => import('./TRX/common/nortesul-frequently-asked-questions-collapse/index');
export const NorteSulProfileCreditLimitContainer = () =>
  import('./TRX/profile/nortesul-profile-credit-limit-container');
export const NorteSulProfileCreditLimitDetails = () => import('./TRX/profile/nortesul-profile-credit-limit-details');
export const NorteSulProfileCreditLimitSummary = () => import('./TRX/profile/nortesul-profile-credit-limit-summary');
export const NorteSulCartProductTaxItems = () => import('./TRX/cart/nortesul-cart-item-tax-items');
export const NorteSulProductTaxItems = () => import('./TRX/product/nortesul-product-tax-items');
export const NorteSulProductAdditionalProperties = () => import('./TRX/product/nortesul-product-additional-properties');
export const NorteSulCommonNavigateToLoginButton = () => import('./TRX/common/nortesul-common-navigate-login-button');
export const NorteSulCartCreditLimitModal = () => import('./TRX/cart/nortesul-cart-credit-limit-modal');
export const NorteSulHeaderDecMed = () => import('./TRX/header/nortesul-header-dec-med');
export const NorteSulContactUs = () => import('./TRX/contact-us');
export const NorteSulBrandPartners = () => import('./TRX/list-partners/nortesul-brand-partners');
export const NorteSulIndustryPartners = () => import('./TRX/list-partners/nortesul-industry-partners');
export const NorteSulProductQuickPurchase = () => import('./TRX/product-listing/nortesul-product-quick-purchase');
export const NorteSulCategoryImage = () => import('./TRX/category/nortesul-category-image');
export const NorteSulProductCarouselDec = () => import('./TRX/common/nortesul-product-carousel-dec');
export const NorteSulProductCarouselMed = () => import('./TRX/common/nortesul-product-carousel-med');
export const NorteSulCommonCategoryBrands = () => import('./TRX/common/nortesul-common-category-brands');
export const NorteSulCommonImage = () => import('./TRX/common/nortesul-common-image');
export const NorteSulCommonNewsletter = () => import('./TRX/common/nortesul-common-newsletter');
export const NorteSulHeaderTopBar = () => import('./TRX/header/nortesul-header-top-bar');
export const NorteSulProductListingFacetedNavigation = () =>
  import('./TRX/product-listing/nortesul-product-listing-faceted-navigation');
export const NorteSulHeaderCollectionNavigationDesktop = () =>
  import('./TRX/header/nortesul-header-collection-navigation-desktop');
export const NorteSulProductListingFacetedNavigationMobile = () =>
  import('./TRX/product-listing/nortesul-product-listing-faceted-navigation/mobile');
export const NorteSulMiniCartDesktop = () => import('./TRX/header/nortesul-header-mini-cart/desktop');
export const NorteSulHeaderMiniCartMobile = () => import('./TRX/header/nortesul-header-mini-cart/mobile');
export const NorteSulCartContainer = () => import('./TRX/cart/nortesul-cart-container');
export const NorteSulExtendedCartItemDetails = () => import('./TRX/cart/nortesul-extended-cart-item-details');
export const NorteSulCartPromotionCodeEntry = () => import('./TRX/cart/nortesul-cart-promotion-code-entry');
export const NorteSulCommonDynamicTitle = () => import('./TRX/common/nortesul-common-dynamic-title');
export const NorteSulProfileDetails = () => import('./TRX/profile/nortesul-profile-details');
export const NorteSulProductListingAddToWishlist = () =>
  import('./TRX/product-listing/nortesul-product-listing-add-to-purchase-list-carousel');
export const NorteSulProductAddToWishlist = () => import('./TRX/common/nortesul-product-add-to-purchase-list-carousel');
export const NorteSulCommonCategory = () => import('./TRX/common/nortesul-common-category');
export const NorteSulProfileNavigationBreadcrumbs = () =>
  import('./TRX/profile/nortesul-profile-navigation-bread-crumbs');
export const NorteSulCommonContainer = () => import('./TRX/common/nortesul-common-container');
export const NorteSulContainerFrequentlyAskedQuestions = () =>
  import('./TRX/common/nortesul-container-duvidas-frequentes');
export const NorteSulHeaderContainerDesktop = () => import('./TRX/header/nortesul-header-container/desktop');
export const NorteSulHeaderContainerMobile = () => import('./TRX/header/nortesul-header-container/mobile');
export const NorteSulHeaderAccountPicker = () => import('./TRX/header/nortesul-header-account-picker');
export const NorteSulProductPrice = () => import('./TRX/product/nortesul-product-price');
export const NorteSulProductResultPrice = () => import('./TRX/product-listing/nortesul-product-result-price');
export const NortesulCommonProductRecommendationsCarousel = () =>
  import('./TRX/common/nortesul-common-product-recommendations-carousel');
export const NorteSulCheckoutPix = () => import('./TRX/checkout/nortesul-checkout-pix');
export const NorteSulCheckoutCreditCard = () => import('./TRX/checkout/nortesul-checkout-credit-card');
export const NorteSulCheckoutPlaceOrderButton = () => import('./TRX/checkout/nortesul-checkout-place-order-button');
export const NorteSulCartOrderSummary = () => import('./TRX/cart/nortesul-cart-order-summary');
//JC Distribuidora
export const JcCommonImage = () => import('./JC/common/jc-common-image');
export const JcHeaderCollectionNavigationDesktop = () => import('./JC/header/jc-header-collection-navigation-desktop');
export const JcHeaderSelector = () => import('./JC/header/jc-header-selector-top-bar');
export const JcFeaturedProducts = () => import('./JC/common/jc-common-featured-products');
export const JcCommonAppSectionVarious = () => import('./JC/common/jc-common-app-section-various');
export const JCHeaderOnboardingModal = () => import('./TRX/header/jc-header-onboarding-modal');
export const JcHeaderTopBar = () => import('./JC/header/jc-header-top-bar');
export const JCProductResultPrice = () => import('./JC/product-listing/jc-product-result-price');
export const JCProductQuantity = () => import('./JC/product/jc-product-quantity');
export const JCProductAddToCartButton = () => import('./JC/product/jc-product-add-to-cart-button');
export const JcCommonCategoryCarousel = () => import('./JC/common/jc-common-category-carousel');
export const JcHeaderLogo = () => import('./JC/header/jc-header-logo');
export const JcHeaderContainerDesktop = () => import('./JC/header/jc-header-container/desktop');
export const JcHeaderContainerMobile = () => import('./JC/header/jc-header-container/mobile');
export const JcHeaderSearchBoxDesktop = () => import('./JC/header/jc-header-search-box-desktop');
export const JcCommonContainer = () => import('./JC/common/jc-common-container');
export const JcHeaderAccountPicker = () => import('./JC/header/jc-header-account-picker');
export const JcHeaderUserProfileLinksDesktop = () => import('./JC/header/jc-header-user-profile-links/desktop');
export const JcHeaderUserProfileLinksMobile = () => import('./JC/header/jc-header-user-profile-links/mobile');
export const JcHeaderDecMed = () => import('./JC/header/jc-header-dec-med');
export const JcHeaderMiniCartDesktop = () => import('./JC/header/jc-header-mini-cart/desktop');
export const JcHeaderMiniCartMobile = () => import('./JC/header/jc-header-mini-cart/mobile');
export const JcHeaderContainerIcons = () => import('./JC/header/jc-header-container-icons');
export const JCProductCarousel = () => import('./JC/common/jc-product-carousel');
export const JCProductQuickView = () => import('./JC/product/jc-product-quick-view');
export const JCAccountsAndContactsRegistration = () => import('./JC/profile/jc-accounts-contacts-registration');
export const JCProductListingContainer = () => import('./JC/product-listing/jc-product-listing');
export const JCProductListingFacetedNavigation = () =>
  import('./JC/product-listing/jc-product-listing-faceted-navigation');
export const JCFacetedNavigationMobile = () =>
  import('./JC/product-listing/jc-product-listing-faceted-navigation/mobile');
export const JcCommonLogo = () => import('./JC/common/jc-common-logo');
export const JcCommonContainerLogoDesktop = () => import('./JC/common/jc-common-container-logo/desktop');
export const JcCommonContainerLogoMobile = () => import('./JC/common/jc-common-container-logo/mobile');
export const JcLoginContainer = () => import('./JC/login/jc-login-container');
export const JcLogin = () => import('./JC/login/jc-login');
export const JcCommonCategoryCarouselBorder = () => import('./JC/common/jc-common-category-carousel-border');
export const JCCommonImageGenericCaousel = () => import('./JC/common/jc-common-image-generic-carousel');
export const JcCheckoutProgressTracker = () => import('./JC/checkout/jc-checkout-progress-tracker');
export const JcCheckoutShippingContainer = () => import('./JC/checkout/jc-checkout-shipping-container');
export const JcCartPromotionCodeEntry = () => import('./JC/checkout/jc-cart-promotion-code-entry');
export const JcCartOrderSummary = () => import('./JC/cart/jc-cart-order-summary');
export const JcCheckoutShippingInformation = () => import('./JC/checkout/jc-checkout-shipping-information');
export const JcCheckoutBackToPreviousButton = () => import('./JC/checkout/jc-checkout-back-to-previous-button');
export const JcCheckoutContinueToReviewOrderButton = () =>
  import('./JC/checkout/jc-checkout-continue-to-review-order-button');
export const JcCheckoutSingleShippingDetails = () => import('./JC/checkout/jc-checkout-single-shipping-details');
export const JcCheckoutMultiShippingWithBopisDetails = () =>
  import('./JC/checkout/jc-checkout-multi-shipping-with-bopis-details');
export const JcCheckoutContinueToPaymentButton = () => import('./JC/checkout/jc-checkout-continue-to-payment-button');
export const JcOrderDetailButton = () => import('./JC/checkout/jc-order-detail-button');
export const JCSulProductAddToWishlist = () => import('./JC/common/jc-product-add-to-purchase-list-carousel');
// export const JCProductImageViewer = () => import('./JC/product/jc-product-product-image-viewer');
export const JcProfileResetPassword = () => import('./JC/profile/jc-profile-reset-password');
export const JcProfileUpdateExpiredPassword = () => import('./JC/profile/jc-profile-update-expired-password');
export const JcCommonNewsletter = () => import('./JC/common/jc-common-newsletter');
export const JcCommonError404 = () => import('./JC/common/jc-common-error-404');
export const JcCommonErro500 = () => import('./JC/common/jc-common-erro-500');
export const JcProductListingAddToWishlist = () =>
  import('./JC/product-listing/jc-product-listing-add-to-purchase-list-carousel');
export const JcProductResultImage = () => import('./JC/product-listing/jc-product-listing-product-result-image');
export const JcProductListingQuickView = () => import('./JC/product/jc-product-listing-quick-view');
export const JcExtendedCartItemDetails = () => import('./JC/cart/jc-extended-cart-item-details');
export const JcProfileDetails = () => import('./JC/profile/jc-profile-details');
export const JcCommonCategoryBrands = () => import('./JC/common/jc-common-category-brands');
export const JcProfileCreditLimitDetails = () => import('./JC/profile/jc-profile-credit-limit-details');
export const JcContainerFrequentlyAskedQuestions = () => import('./JC/common/jc-container-duvidas-frequentes');
export const JcCollapsible = () => import('./JC/common/jc-frequently-asked-questions-collapse');
export const JcContactUs = () => import('./JC/contact-us');
export const JcProductQuickPurchase = () => import('./JC/product-listing/jc-product-quick-purchase');
export const JcCartContainer = () => import('./JC/cart/jc-cart-container');
export const JcCheckoutMultiShippingWithBopisDetailsSt = () =>
  import('./JC/checkout/jc-checkout-multi-shipping-with-bopis-details-st');
export const JcProductPrice = () => import('./JC/product/jc-product-price');
export const JcProductAdditionalProperties = () => import('./JC/product/jc-product-additional-properties');
export const JcLoginContainerVendor = () => import('./JC/login/jc-login-container-vendor');
export const JcLoginVendor = () => import('./JC/login/jc-login-vendor');
export const JcAccountsAndContactsRegistrationVendor = () =>
  import('./JC/profile/jc-accounts-contacts-registration-vendor');
export const JcAccountTypeSelectorContainerVendor = () =>
  import('./JC/profile/jc-accounts-contacts-registration-container-vendor');
export const JcFooterLinksListVendor = () => import('./JC/footer/jc-footer-links-list-vendor');
export const JcCheckoutTermInvoice = () => import('./JC/checkout/jc-checkout-term-invoice');
export const JcCheckoutOrderSummary = () => import('./JC/checkout/jc-checkout-order-summary');
export const JcCheckoutPaymentsContainer = () => import('./JC/checkout/jc-checkout-payments-container');
export const JcCheckoutPix = () => import('./JC/checkout/jc-checkout-pix');
export const JcCheckoutCreditCard = () => import('./JC/checkout/jc-checkout-credit-card');
export const JcCheckoutOrderConfirmation = () => import('./JC/checkout/jc-checkout-order-confirmation');
export const JcCheckoutOrderConfirmationContainer = () =>
  import('./JC/checkout/jc-checkout-order-confirmation-container');
export const JcProductListingDynamicProductList = () =>
  import('./JC/product-listing/jc-product-listing-dynamic-product-list');
export const JcProductShortDescription = () => import('./JC/product/jc-product-short-description');
export const JcPdpImageViewer = () => import('./JC/product/jc-pdp-image-viewer');
export const JcHeaderCollectionNavigationMobile = () => import('./JC/header/jc-header-collection-navigation-mobile');
export const JcProfileShippingInformation = () => import('./JC/profile/jc-profile-shipping-information');
export const JcProfileOrderDetailsContainer = () => import('./JC/profile/jc-profile-order-details-container');
export const JcCommonSearchBoxMobile = () => import('./JC/common/jc-common-search-box-mobile');
export const JcAccountsAndContactsRegistrationCollaborator = () =>
  import('./JC/profile/jc-accounts-contacts-registration-collaborator');
export const JcLoginCollaborator = () => import('./JC/login/jc-login-collaborator');
export const JcCheckoutPlaceOrderButton = () => import('./JC/checkout/jc-checkout-place-order-button');
